import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";

import "../../styles/blog/layout.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../Header";
import Footer from "../Footer";
import LayoutBase from "./LayoutBase";
import PrivacyBanner from "./PrivacyBanner";

// eslint-disable-next-line arrow-body-style
const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <LayoutBase>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <Header siteTitle="Temp title" />
      {children}
      <Footer />
      <PrivacyBanner />
    </LayoutBase>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
